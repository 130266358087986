// Colours
$colour-black: #0d0d0e !default;
$colour-darkgrey: #262626 !default;

$colour-text: $colour-darkgrey !default;
$colour-headings: $colour-darkgrey !default;
$colour-link: $colour-darkgrey !default;
$colour-link-hover: $colour-black !default;

// Fonts
$title-font-family: futura-pt, 'Montserrat', 'Helvetica Neue', Helvetica, sans-serif !default;
$text-font-family: futura-pt, 'Montserrat', 'Helvetica Neue', Helvetica, sans-serif !default;
$alt-font-family: 'Montserrat', 'Helvetica Neue', Helvetica, sans-serif !default;
$medium-font-family: futura-pt, sans-serif !default;
$bold-font-family: futura-pt-bold, sans-serif !default;

// Font Size
$html-font-size: 62.5%;
$font-size: 16px !default;
$font-size-small: $font-size - 0px !default;
$font-size-big: $font-size + 10px !default;
$font-size-large: $font-size + 20px !default;
$line-height: 1.1;

// Font Size headings
$heading-font-size-title: 54px !default;
$heading-font-size-1: 2.5em !default;
$heading-font-size-2: 1.95em !default;
$heading-font-size-3: 1.6em !default;
$heading-font-size-4: 1.4em !default;
$heading-font-size-5: 1.0em !default;
$heading-font-size-6: .9em !default;
$heading-line-height-title: 54px !default;
$heading-line-height-1: 1.5em !default;
$heading-line-height-2: 1.5em !default;
$heading-line-height-3: 1.5em !default;
$heading-line-height-4: 1.5em !default;
$heading-line-height-5: auto;
$heading-line-height-6: auto;

// Baseline
$base-line: 24px !default;
$base-line-small: $base-line - 2px !default;
$base-line-big: $base-line + 2px !default;

// Baseline headings
$heading-base-line-title: 54px !default;
$heading-base-line-1: 45px !default;
$heading-base-line-2: 33px !default;
$heading-base-line-3: 32px !default;
$heading-base-line-4: 10px !default;
$heading-base-line-5: 28px !default;
$heading-base-line-6: 24px !default;

// Font Weights
$font-weight-light: 100 !default;
$font-weight-normal: 200 !default;
$font-weight-medium: 700 !default;
$font-weight-bold: 900 !default;

// Margins
$heading-margin-bottom: $font-size*0.5 !default;
$text-margin-bottom: $font-size !default;

// Definitions

html,
body {
  font-size: $font-size;
  line-height: $base-line;
  text-align: left;
}

body, input {
  font-family: $text-font-family;
  color: $colour-text;
  // letter-spacing: 0.5px;
  letter-spacing: 0.025em;
  line-height: $line-height;
}

// Links
a {
  color: $colour-link;
  text-decoration: none;

  &:visited,
  &:active {
    color: inherit;
    text-decoration: inherit;
  }
}

a:hover {
  color: $colour-link-hover;
}

// Headings
h1.title,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $title-font-family;
  font-weight: bold;
  color: $colour-headings;
  text-rendering: optimizeLegibility;
  margin-bottom: $heading-margin-bottom;
  letter-spacing: .8px;
}

h1.title {
  font-size: $heading-font-size-title;
  line-height: $heading-line-height-title;
  margin-bottom: $heading-margin-bottom / 2;
}

h1,
.h1 {
  font-size: $heading-font-size-1;
  line-height: $heading-line-height-1;
}

h2,
.h2 {
  font-size: $heading-font-size-2;
  line-height: $heading-line-height-2;
}

h3,
.h3 {
  font-size: $heading-font-size-3;
  line-height: $heading-line-height-3;
}

h4,
.h4 {
  font-size: $heading-font-size-4;
  line-height: $heading-line-height-4;
}

h5,
.h5 {
  font-size: $heading-font-size-5;
  line-height: $heading-line-height-5;
}

h6,
.h6 {
  font-size: $heading-font-size-6;
  line-height: $heading-line-height-6;
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: inherit;
}

// Top Space
p,
ul,
ol,
dl,
blockquote,
hr,
pre,
table,
form,
figure,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
}

p,
ul,
ol,
dl,
blockquote,
hr,
pre,
table,
form,
figure {
  + h2,
  + h3,
  + h4,
  + h5,
  + h6 {
    margin-top: $base-line;
  }
}

h1, h2, h3 {
  font-family: $bold-font-family;
}

h4, h5, h6 {
  font-family: $medium-font-family;
}

a:hover {
  text-decoration: underline;
}

// Lists
ul, ol {
  &,
  & ul,
  & ol {
    margin: 0 0 0 $base-line;
  }
}

ol li {
  list-style-type: decimal;
}

ol ol li {
  list-style-type: lower-alpha;
}

ol ol ol li {
  list-style-type: lower-roman;
}

ul li {
  list-style-type: circle;
}

// Block Tags
p,
blockquote,
hr,
pre,
ol,
ul,
dl,
table,
fieldset,
figure,
address,
form {
  margin-bottom: $text-margin-bottom;
}

hr {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  margin-top: -1px;
}

// Transforms
.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

.italic {
  font-style: italic !important;
}

.light {
  font-weight: lighter !important;
}

.strong {
  font-weight: bolder !important;
}

.normal {
  font-weight: normal !important;
}

.small {
  font-size: $font-size-small;
}

.big {
  font-size: $font-size-big;
}

.large {
  font-size: $font-size-large;
}

.end {
  margin-bottom: 0 !important;
}

.alt-font {
  font-family: $alt-font-family;
}

// Forms

input:focus {
  outline: none !important;
}

input, textarea {
  outline: none !important;
  resize: none;
  border: none;
}
